body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.demo-1 {
	--color-text: #fff;
	--color-bg: #000;
	--color-link: #f9d77e;
	--color-link-hover: #fff;
	--color-info: #efc453;
	--glitch-width: 100vw;
	--glitch-height: 100vh;
	--gap-horizontal: 10px;
	--gap-vertical: 5px;
	--time-anim: 4s;
	--delay-anim: 2s;
	--blend-mode-1: none;
	--blend-mode-2: none;
	--blend-mode-3: none;
	--blend-mode-4: none;
	--blend-mode-5: overlay;
	--blend-color-1: transparent;
	--blend-color-2: transparent;
	--blend-color-3: transparent;
	--blend-color-4: transparent;
	--blend-color-5: #af4949;
}

html {
	background: #373087;
}

html, body
{
    height: 100%;
}

.bgOne {
	position: absolute;
	background-color: #373087;
	height: 100vh;
	width: 100%;
}

.bgOne:first-of-type {
	/* background-image: linear-gradient(to bottom right, #332c7d, #482077); */
	background-image: -webkit-gradient(linear, left top, right bottom, from(#090921), to(#482077));
	background-image: -webkit-linear-gradient(top left, #090921, #482077);
	background-image: linear-gradient(to bottom right, #090921, #482077);
	/* background: linear-gradient(323deg, #271f73, #611bb3); */
	background-size: 400% 400%;
	-webkit-animation: AnimationName 8s ease infinite;
	animation: AnimationName 8s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:4% 0%}
    50%{background-position:97% 100%}
    100%{background-position:4% 0%}
}
@keyframes AnimationName { 
    0%{background-position:4% 0%}
    50%{background-position:97% 100%}
    100%{background-position:4% 0%}
}

.headerFill {
	height: 100vh;
	color: #ffffff;
	background: #373087;
	/* background: #1c2633; */
	/* background: #fe7300; */
	/* #fe7300; */
	/* #fe9d00; */
	/* background-image: url('/bgre.png'); */
	/* background-image: url('/bgre2.jpg'); */
	background-size: cover;
	background-position: bottom center;
	overflow: hidden;	
	/* background-size: 600% 600%; */
	text-shadow: 0.25rem -0.25rem rgba(255, 0, 255, 0.75), -0.25rem -0.25rem rgba(0, 255, 255, 0.75), 0 0.5rem rgba(255, 255, 0, 0.75);	
}

@font-face {
    font-family: "customBarlow";
    src: url(/static/media/Barlow-Regular.81a8f8bb.woff) format('woff');
}

.rectangle {
	height: 15vh;
	width: 100%;
	background-color: #373087;
	margin-bottom: 10vh;
	box-shadow: 0px 0px 70px rgba(244, 32, 253, 0.3);
}

.rectangle:first-of-type {
	background-image: url(/static/media/bgtop.89b448a9.png);
	background-size: cover;
	-webkit-animation: tilt 8s infinite;
}

@-webkit-keyframes tilt {
	0% {
		margin-top:0vh;margin-bottom:10vh;
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-filter: blur(0px);
		        filter: blur(0px);
	}
	50% {
		margin-top:0vh;margin-bottom:10vh;
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-filter: blur(0px);
		        filter: blur(0px);
	}
	50.001% {
		margin-top:1vh;margin-bottom:9vh;
		-webkit-transform: rotate(2deg);
		transform: rotate(2deg);
		-webkit-filter: blur(0px);
		        filter: blur(0px);
	}
	51.002% {
		margin-top:0vh;margin-bottom:10vh;
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	60%{-webkit-filter: blur(2px);filter: blur(2px);}
	90%{-webkit-filter: blur(0px);filter: blur(0px);}
	100% {
		margin-top:0vh;margin-bottom:10vh;
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-filter: blur(0px);
		        filter: blur(0px);
	}
}

.rectangle:nth-of-type(2) {
	background-image: url(/static/media/bgmidflip.795b9c85.png);
	background-size: cover;
	/* -moz-transform: scaleX(-1); */
    /* -o-transform: scaleX(-1); */
    /* -webkit-transform: scaleX(-1); */
    /* transform: scaleX(-1); */
    /* filter: FlipH; */
	/* -ms-filter: "FlipH"; */
	-webkit-animation: tilt2 9s infinite;
}

@-webkit-keyframes tilt2 {
	0% {
		margin-left:0px;
		width: 100%;
		background-image: url(/static/media/bgmidflip.795b9c85.png);
		-webkit-filter: blur(0px);
		        filter: blur(0px);
	}
	40% {
		margin-left:0px;
		width: 100%;
		background-image: url(/static/media/bgmidflip.795b9c85.png);
		-webkit-filter: blur(0px);
		        filter: blur(0px);
	}
	40.001% {
		margin-left:-2px;
		width: 100%;
		background-image: url(/static/media/bgmid.dd39aa68.png);
		-webkit-filter: blur(4px);
		        filter: blur(4px);
	}
	42.002% {
		margin-left:0px;
		width: 100%;
		background-image: url(/static/media/bgmidflip.795b9c85.png);
	}
	50%{
		-webkit-filter: blur(0px);
		        filter: blur(0px);
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	88.7%{
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	89%{
		-webkit-transform: scale(1.1);
		        transform: scale(1.1);
	}
	89.3%{
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	100% {
		margin-left:0px;
		width: 100%;
		background-image: url(/static/media/bgmidflip.795b9c85.png);
		-webkit-filter: blur(0px);
		        filter: blur(0px);
	}
}

.rectangle:last-child {
	margin-bottom: 0;
	background-image: url(/static/media/bgbottom.ed39ca4c.png);
	background-size: cover;
	-webkit-animation: tilt3 7s infinite;
}

@-webkit-keyframes tilt3 {
	0%{
		-webkit-transform: scale(1);
		        transform: scale(1);
		opacity: 1;
	}
	9%{
		opacity: 1;
	}
	10%{
		opacity: 0;
	}
	11%{
		opacity: 1;
	}
	15%{
		opacity: 1;
	}
	16%{
		opacity: 0;
	}
	17%{
		opacity: 1;
	}
	24%{
		opacity: 1;
	}
	25%{
		opacity: 0;
	}
	26%{
		opacity: 0.7;
	}
	27%{
		opacity: 0.4;
	}
	28%{
		opacity: 1;
	}
	60%{
		-webkit-transform: scale(1);
		        transform: scale(1);
		opacity: 1;
	}
	60.001%{
		
		/* opacity: 0.7; */
	}
	61%{
		-webkit-transform: scale(1);
		        transform: scale(1);
		/* opacity: 0.4; */
	}
	66%{
		opacity: 1;
	}
	100%{
		-webkit-transform: scale(1);
		        transform: scale(1);
		opacity: 1;
	}
}

.headLeftInner:nth-child(1) {
	background-color: var(--blend-color-1);
	-webkit-transform: translate3d(0,0,0);
	        transform: translate3d(0,0,0);
	background-blend-mode: var(--blend-mode-1);
	-webkit-animation-name: glitch-anim-1;
	        animation-name: glitch-anim-1;
}

.headLeftInner:nth-child(1) {
	background-color: var(--blend-color-2);
	-webkit-transform: translate3d(0,0,0);
	        transform: translate3d(0,0,0);
	background-blend-mode: var(--blend-mode-2);
	-webkit-animation-name: glitch-anim-2;
	        animation-name: glitch-anim-2;
}

.headerFillInner, .bgOne {
	height: 100vh;
	-webkit-animation: AnimationName 6s ease infinite;
	-moz-animation: AnimationName 6s ease infinite;
	/* background: linear-gradient(270deg, rgba(249, 21, 21, 0.7), rgba(43, 249, 21, 0.7), rgba(249, 234, 21, 0.7)), url('/bgre.png'); */
	/* background:-webkit-linear-gradient(right, rgba(249, 21, 21, 0.7), rgba(43, 249, 21, 0.7), rgba(249, 234, 21, 0.7)), url('/bgre.png'); */
	/* background: -webkit-linear-gradient(right, rgba(254, 157, 0, 0.4), rgba(35, 47, 62, 0.4), rgba(0, 221, 221, 0.4)); */
	/* background-size: 600% 600%; */
	/* background-size: cover; */
	/* background-position: bottom center; */
	/* animation: AnimationName 20s ease infinite; */
	text-shadow: 0px 1px 1px #7290AC;
	background-color: #373087;
}
.bgOne:nth-of-type(2) {
	-webkit-transform: translate3d(0,0,0);
	        transform: translate3d(0,0,0);
	background-blend-mode: var(--blend-mode-1);
	-webkit-animation-name: glitch-anim-1;
	        animation-name: glitch-anim-1;
	-webkit-animation-duration: var(--time-ani	m);
	        animation-duration: var(--time-ani	m);
	-webkit-animation-timing-function: linear;
	        animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
	/* background-color: #332c7d; */
	background-color: #241345;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName { 
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

.headLeft {
	/* position: fixed; */
    left: 0;
    top: 45vh;

    -webkit-transform: translateY(-50%);
			transform: translateY(-50%);
	padding: 0;
	/* font-family: 'Montserrat Alternates', sans-serif; */
	/* font-size: 200px; */
	/* font-weight: 800; */
	/* color: rgba(255, 0, 0, 0.5);	 */
	/* background-image: url('../../stack11.jpg') center; */
	/* background-size: cover; */
	/* mix-blend-mode: multiply; */
	/* background: url('bgline.jpg'); */
	background-size: cover;
}

.headLeftInner {
	/* padding: 0 15px; */
	/* background: #19354c; */
}

.headLeft span {
	padding-left: 10px;
	/* mix-blend-mode: difference; */
}

.headRight {
	float: right;
}

.contentFill {
    height: 100%;
}

svg {
	font-family: 'Prompt', sans-serif;
}

body {
	overflow-x: hidden;
}

hr {
	background-color: #ffffff;
}

.App {
	text-align: center;
}

.App-logo {
	-webkit-animation: App-logo-spin infinite 20s linear;
	        animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	/* background-color: #282c34; */
	background-color: #1a1b1d;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes App-logo-spin {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

.techgallery {
	background-color: #1c2633;
}

.navbar {
	width: 100%;
}

.navbar-brand img {
	background: -webkit-linear-gradient(314deg, #f71818, #f79018, #7cf718, #18a6f7, #bb18f7);
	background: linear-gradient(136deg, #f71818, #f79018, #7cf718, #18a6f7, #bb18f7);
	background-size: 1000% 1000%;
	-webkit-animation: BackgroundBox 20s linear infinite;
	animation: BackgroundBox 20s linear infinite;
}

.navbar-nav {
	font-family: 'Barlow', sans-serif;
	text-transform: uppercase;
	/* font-weight: bold; */
	/* font-size: calc(5px + 2vmin); */
	font-size: 18px;
	font-weight: 400;
}

.navbar-nav:first-of-type {
	font-size: 30px;
}

.box {
	margin-top: 30px;
	border: 2px solid #ffffff;
	padding: 30px 20px;
	background-color: #331756;
}

.box p {
	margin: 0;
}

.box h4 {
	margin-bottom: 20px;
}

.element1 {
	/* width: 37px; */
	-webkit-animation: colorchange 2s infinite alternate;
	background-image :url(/static/media/rslogo1.53b4a300.png);
	background-size: cover;
	width: 150px;
	height: 45px;
}

.element2 {
	font-family: 'Barlow', sans-serif!important;
}

@-webkit-keyframes colorchange {
	0% {background-image :url(/static/media/rslogo2.994b2b59.png);}
	5% {background-image :url(/static/media/rslogo1.53b4a300.png);}
	20% {background-image :url(/static/media/rslogo2.994b2b59.png);}
	100% {background-image :url(/static/media/rslogo2.994b2b59.png);}
}

@-webkit-keyframes textme {
	0%{
		color: white;
		/* font-family: 'Barlow', sans-serif; */
	}
	50%{
		color: red;
		/* font-family: 'customBarlow', sans-seriff; */
	}
	100%{
		color: white;
		/* font-family: 'Barlow', sans-serif; */
	}
}

@keyframes textme {
	0%{
		color: white;
		/* font-family: 'Barlow', sans-serif; */
	}
	50%{
		color: red;
		/* font-family: 'customBarlow', sans-seriff; */
	}
	100%{
		color: white;
		/* font-family: 'Barlow', sans-serif; */
	}
}

.navbar-nav a:hover {
	color: #00dddd!important;
}

@-webkit-keyframes BackgroundBox {
    0%{background-position:0% 9%}
    50%{background-position:100% 92%}
    100%{background-position:0% 9%}
}
@keyframes BackgroundBox { 
    0%{background-position:0% 9%}
    50%{background-position:100% 92%}
    100%{background-position:0% 9%}
}

.h1strong {
	font-weight: bold;
	font-size: 4rem;
	-webkit-transform: translateY(6rem);
	        transform: translateY(6rem);
	-webkit-animation: up 500ms linear forwards;
	        animation: up 500ms linear forwards;
	z-index: 1;
	text-shadow: 0px 1px 1px rgba(255,255,255,1);
	
	&::before,
	&::after {
		position: absolute;
		content: '';
		width: 0px;
		height: 1px;
		left: 0;
		background-color: rgba(0,0,0,0.2);
		z-index: -1;
	}
	
	&::before {
		top: 1.4rem;
		-webkit-animation: draw 500ms linear 1s forwards;
		        animation: draw 500ms linear 1s forwards;
	}
	
	&::after {
		bottom: 0.4rem;
		-webkit-animation: draw 500ms linear 1s forwards;
		        animation: draw 500ms linear 1s forwards;
	}
}

.thankYouText {
	display: none;
}

.right {
	text-align: right;
}

.detailsRow {
	padding-top: 80px;
	padding-bottom: 80px;
	background-color: #3a1a64;
	color: #ffffff;
	font-family: 'Barlow', sans-serif;
	font-weight: 600;
}

.detailsRow h3 {
	text-transform: uppercase;
	font-size: 3.5rem;
	margin-bottom: 18px;
	font-family: 'Montserrat', sans-serif;
	font-weight: 800;
}

.detailsRow p {
	font-size: 18px;
	/* line-height: 2em; */
}

.italic {
	font-style: italic;
}

.infoBox {
	margin-bottom: 30px;
	position: relative;
	overflow: hidden;
	z-index: 999;
}

.infoBox p, .formText {
	font-size: calc(10px + 1vmin);
}

.formText {
	text-align: left;
}

.infoBox h1 {
	/* text-shadow: 1px 1px 1px rgb(0, 221, 221, 0.8); */
	/* text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15); */
	font-family: 'Monda', sans-serif;
	text-transform: uppercase;
	margin-top: 32vh;
	font-size: 3rem;
	margin-bottom: 5%;
	text-align: center;
	/* animation:  typing 3.5s steps(40, end), blink-caret .75s step-end infinite; */
}

.typewriter h1 {
	z-index: 999;
	position: relative;
	color: #fff;
	font-family: monospace;
	overflow: hidden; /* Ensures the content is not revealed until the animation */
	border-right: .15em solid orange; /* The typwriter cursor */
	white-space: nowrap; /* Keeps the content on a single line */
	margin: 0 auto; /* Gives that scrolling effect as the typing happens */
	letter-spacing: .15em; /* Adjust as needed */
	-webkit-animation: 
	  typing 3.5s steps(30, end),
	  blink-caret .5s step-end infinite;
	        animation: 
	  typing 3.5s steps(30, end),
	  blink-caret .5s step-end infinite;
  }
  
  /* The typing effect */
  @-webkit-keyframes typing {
	from { width: 0 }
	to { width: 100% }
  }
  @keyframes typing {
	from { width: 0 }
	to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  @-webkit-keyframes blink-caret {
	from, to { border-color: transparent }
	50% { border-color: orange }
  }
  @keyframes blink-caret {
	from, to { border-color: transparent }
	50% { border-color: orange }
  }

/* video {
	position: absolute;
  top: 0%;
  left: 0%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
  overflow: hidden;
  z-index: 999;
} */

header video { width: 100%; }
svg { 
  width: 100%;
  position:absolute;
  top: 0;
  left: 0;
  height: 100%;
}
svg text {
  font-family: Biko, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 38px;
}
svg rect {
  fill: white;
}
svg > rect {
    -webkit-mask: url(#mask);
    mask: url(#mask);
}

.newStyle {
	background: rgba(0, 221, 221, 0.6);
	text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15);
	padding: 20px;
}

.infoBox h2 {
	text-align: center;
	font-size: 1.2rem;
	margin: 0 auto;
	margin-bottom: 8px;
	font-weight: bold;
}

.infoBox ul {
	font-size: calc(8px + 1vmin);
	text-align: left;
	margin: 0;
}

.lead {
	margin-bottom: 5%;
	text-align: left;
}

.bg-dark {
	/* background-color: transparent!important; */
	 /* #232f3e!important; */
}

.card-deck .col-lg-3 {
	padding: 0;
	margin-bottom: 40px;
}

.card {
	height: 100%;
}

.bg-darkBlue {
	background-color: #232f3e!important;
}

.bulletCol {
	margin-bottom: 30px;
}

.bulletCol ul {
	list-style: none;
	padding: 0;
}

.bulletCol ul li:before {
	content:'';
	display:inline-block;
	height:15px;
	width:15px;
	background-image:url(/static/media/64px_c-check.becf8aae.svg);
	background-size:contain;
	background-repeat:no-repeat;
	padding-left: 1.6em;
	position: relative;
	top: 2px;
}

.infoBox ul li {
	padding-bottom: 10px;
}

@media only screen and (max-width: 767px) {
	.fixedMid {
		margin: 0 auto;
		padding-bottom: 80px;
		width: 60%;
	}

	.comptext {
		margin: 0 auto;
		width: 100%;
	}

	.box {
		margin: 20px;
	}
}

@media only screen and (min-width : 768px) {
	.fixedMid {
		/* position: fixed; */
		/* top: 50%; */
		margin-top: 20%;
	}

	.box:first-of-type {
		margin-right: 20px;
		margin-left: -20px;
	}
	
	.box:last-of-type {
		margin-left: 20px;
		margin-right: -20px;
	}
}

@media only screen and (max-width: 767px) {
	.insideTemplate .fixedMid {
		margin: 0 auto;
		padding-bottom: 80px;
		width: 60%;
		margin-top: 40px;
	}
}

@media only screen and (min-width : 768px) {
	.insideTemplate .fixedMid {
		margin-top: 5%;
	}
}

.breadcrumb {
	font-size: calc(6px + 1.5vmin);
}

.cardRow {
	background: #232f3e;
	position: relative;
	padding: 60px;
	padding-bottom: 20px;
}

.cardRowDev {
	border-bottom: 2px solid #00dddd;
}

.cardRowClient {
	border-bottom: 2px solid #fe7300;
}

.bannerRow {
	/* background: #ffffff url('bg6.jpg'); */
	background: #232f3e;
	background:-webkit-gradient(linear,left bottom, left top,from(rgba(255,255,255,0.9)),to(rgba(255,255,255,0.9))),url(/static/media/bg6.77360658.jpg);
	background:-webkit-linear-gradient(bottom,rgba(255,255,255,0.9),rgba(255,255,255,0.9)),url(/static/media/bg6.77360658.jpg);
	background:linear-gradient(0deg,rgba(255,255,255,0.9),rgba(255,255,255,0.9)),url(/static/media/bg6.77360658.jpg);
	background-repeat: repeat;
	background-size: cover;
	/* height: 100px; */
	position: relative;
	padding: 120px 80px;
}

.devButton {
	background-color: #00dddd;
	border-color: #00aaaa;
}

.devButton:hover {
	background-color: #00aaaa;
	border-color: #00dddd;
}

.clientButton {
	background-color: #fe9d00;
	border-color: #fe7300;
}

.clientButton:hover {
	background-color: #fe7300;
	border-color: #fe9d00;
}

.bannerRow h2 {
	color: #232f3e;
}

.bannerRow p {
	color: #232f3e;
	font-size: calc(10px + 1.5vmin);
	margin-top: 20px;
}

.bannerRow a {
	color: #fe9d00;
}

.signupRow {
	position: relative;
	padding: 140px 80px;
	/* background-image: linear-gradient(to left, transparent, #232f3e), url("bg4.jpg"); */
	background: -webkit-gradient(linear, left top, right top, from(#232f3e), color-stop(25%, #232f3e), color-stop(50%, #232f3e), color-stop(75%, transparent)), url(/static/media/bg5.5f3482a2.jpg);
	background: -webkit-linear-gradient(left, #232f3e 0%, #232f3e 25%, #232f3e 50%, transparent 75%), url(/static/media/bg5.5f3482a2.jpg);
	background: linear-gradient(to right, #232f3e 0%, #232f3e 25%, #232f3e 50%, transparent 75%), url(/static/media/bg5.5f3482a2.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	border-bottom-left-radius: 300% 80%;
	border-bottom-right-radius: 300% 80%;
}

.signupRowDev {
	border-top: 2px solid #00dddd;
	border-bottom: 50px solid #00dddd;
}

.signupRowClient {
	border-top: 2px solid #fe7300;
	border-bottom: 5px solid #fe7300;
}

.signupContainer {
	position: relative;
	background-color: #ffffff;
}

.signupRow p {
	margin: 0;
}

.footerRow {
	padding: 60px;
	font-size: calc(10px + 1vmin);
	position: relative;
	background-color: #ffffff;
	color: #232f3e;
}

.footerRow a {
	color: #8a52d4;
}

.footerRow a:first-of-type {
	margin-right: 5%;
}

.footerRow ul {
	list-style: none;
	text-align: left;
}

#form2 {
	margin-top: 40px;
}

.card-img-top {
	width: 30%;
	margin: 0 auto;
	margin-top: 20px;
}

.card-text {
	font-size: calc(5px + 1vmin)
}

.card-footer .text-muted {
	font-size: calc(8px + 1vmin);
	color: #fe7300!important;
}

.card-footer .card-footer-dev {
	color: #00dddd!important;
}

.align-items-center {
	align-items: center!important;
}

.d-flex {
	display: flex!important;
}

.bgGrey {
	/* background-color: rgba(40,44,52, 0.2); */
}

.boldThree {
	text-transform: uppercase;
	background-color: #232f3e;
	position: relative;
	box-shadow: 0 0 70px rgba(244,32,253,.3);
}

.boldThree h2 {
	font-size: 2.8rem;
	font-weight: 100;
}

.boldThree h2 strong {
	font-weight: 600;
}

@media screen and (max-width: 991px) {
	.boldThree .col-md-12 {
		padding: 40px 100px 0 100px;
	}
}

@media screen and (min-width : 992px) and (max-width : 1369px) {
	.boldThree .col-md-12 {
		padding: 30px 30px 0 30px;
	}
}

@media screen and (min-width: 1370px) {
	.boldThree .col-md-12 {
		padding: 80px 100px 0 100px;
	}
}

.boldThree .col-md-12 p {
	font-size: 0.65em;
	font-weight: 300;
	text-transform: none;
	margin-bottom: 240px;
}

.boldThree .col-md-12 img {
	margin-top: 30px;
	width: 100%;
	position: absolute;
	bottom: 0;
	max-width: 350px;
	margin-left: -175px;
	left: 50%;
}

.boldThree .col-md-12:nth-child(1) {
	background-color: rgba(255, 28, 255, 0.5);
}

.boldThree .col-md-12:nth-child(2) {
	background-color: rgba(63, 38, 121, 0.5);
}

.boldThree .col-md-12:nth-child(3) {
	background-color: rgba(57, 177, 254, 0.5);
}

/* .bgGrey:first-of-type {
	margin-top: 105px;
} */

.backgroundDark {
	/* background: rgb(40,44,52)!important; */
	background: #232f3e!important;
	position: fixed;
	width: 100%;
	z-index: 1000;
}

.backgroundDarkDev {
	border-bottom: 2px solid #00dddd;
}

.backgroundDarkClient {
	/* border-bottom: 1px solid #fe7300; */
	/* background: linear-gradient(to bottom,rgba(0,0,0,.75) 0%,transparent 100%); */
	/* background: linear-gradient(to bottom,#009292 0%,transparent 100%); */
	/* background : #19364C!important; */
	background: none!important;
	padding: 1% 5%;
	z-index: 999;
}

.disclaimer {
	font-size: calc(8px + 1vmin);
	margin-top: 5px!important;
	color: #969696;
}

.center-text {
	text-align: center;
	min-width: 280px;
}

.titlecontainer .titleinner {
	position: relative;
	display: block;
	margin: 0;
	font-size: 4rem;
	line-height: 1;
	-webkit-transform: translateY(6rem);
	        transform: translateY(6rem);
	-webkit-animation: up 500ms linear forwards;
	        animation: up 500ms linear forwards;
	z-index: 1;
	text-shadow: 0px 1px 1px rgba(255,255,255,1);
	
	&::before,
	&::after {
		position: absolute;
		content: '';
		width: 0px;
		height: 1px;
		left: 0;
		background-color: rgba(0,0,0,0.2);
		z-index: -1;
	}
	
	&::before {
		top: 1.4rem;
		-webkit-animation: draw 500ms linear 1s forwards;
		        animation: draw 500ms linear 1s forwards;
	}
	
	&::after {
		bottom: 0.4rem;
		-webkit-animation: draw 500ms linear 1s forwards;
		        animation: draw 500ms linear 1s forwards;
	}
	
}

.titlestring {
	font-size: 0;
	position: relative;
	overflow: hidden;
	padding-bottom: 0.4rem;
}

@-webkit-keyframes up {
	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@keyframes up {
	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@-webkit-keyframes draw {
	100% {
		width: 100%;
	}	
}

@keyframes draw {
	100% {
		width: 100%;
	}	
}

canvas {
	position: fixed;
	/* width: 100%; */
	/* height: 100%; */
}

@-webkit-keyframes move_wave {
    0% {
        -webkit-transform: translateX(0) translateZ(0) scaleY(1);
                transform: translateX(0) translateZ(0) scaleY(1)
    }
    50% {
        -webkit-transform: translateX(-25%) translateZ(0) scaleY(0.55);
                transform: translateX(-25%) translateZ(0) scaleY(0.55)
    }
    100% {
        -webkit-transform: translateX(-50%) translateZ(0) scaleY(1);
                transform: translateX(-50%) translateZ(0) scaleY(1)
    }
}

@keyframes move_wave {
    0% {
        -webkit-transform: translateX(0) translateZ(0) scaleY(1);
                transform: translateX(0) translateZ(0) scaleY(1)
    }
    50% {
        -webkit-transform: translateX(-25%) translateZ(0) scaleY(0.55);
                transform: translateX(-25%) translateZ(0) scaleY(0.55)
    }
    100% {
        -webkit-transform: translateX(-50%) translateZ(0) scaleY(1);
                transform: translateX(-50%) translateZ(0) scaleY(1)
    }
}
.waveWrapper {
    overflow: hidden;
    position: absolute;
    left: -20%;
    right: 0;
    bottom: 0;
    top: -20%;
    margin: auto;
}
.waveWrapperInner {
    position: absolute;
    width: 100%;
    overflow: hidden;
    height: 120vh;
	bottom: -1px;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#009292), to(#00dddd));
	background-image: -webkit-linear-gradient(#009292, #00dddd);
	background-image: linear-gradient(#009292, #00dddd);
	width: 140%;
    /* background-image: linear-gradient(to top, #00dddd 20%, #27273c 80%); */
}
.bgTop {
    z-index: 15;
    opacity: 0.6;
}
.bgMiddle {
    z-index: 10;
    opacity: 0.75;
}
.bgBottom {
    z-index: 5;
}
.wave {
    position: absolute;
    left: 0;
    width: 200%;
    height: 100%;
    background-repeat: repeat no-repeat;
    background-position: 0 bottom;
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
}
.waveTop {
	background-size: 50% 100px;
	background-image: url('http://front-end-noobs.com/jecko/img/wave-top.png');
}
.waveAnimation .waveTop {
  animation: move-wave 3s;
   -webkit-animation: move-wave 3s;
   -webkit-animation-delay: 1s;
   animation-delay: 1s;
}
.waveMiddle {
	background-size: 50% 120px;
	background-image: url('http://front-end-noobs.com/jecko/img/wave-mid.png');
}
.waveAnimation .waveMiddle {
    -webkit-animation: move_wave 10s linear infinite;
            animation: move_wave 10s linear infinite;
}
.waveBottom {
	background-size: 50% 100px;
	background-image: url('http://front-end-noobs.com/jecko/img/wave-bot.png');
}
.waveAnimation .waveBottom {
    -webkit-animation: move_wave 15s linear infinite;
            animation: move_wave 15s linear infinite;
}

.whiteSignup {
	background: #2e709e;
	color: #000000;
	padding-top: 40px;
	padding-bottom: 40px;
	min-height: 430px;
}

.interiorSignup .row {
	box-shadow: 6px 6px 15px #cccccc;
}

.interiorSignup .col-md-5 {
	margin: 0;
	padding: 80px 70px 120px 70px;
	background-color: #ffffff;
}

.interiorSignup .col-md-7 {
	margin: 0;
	padding: 0;
	overflow: hidden;
}

.interiorSignup h2 {
	text-transform: uppercase;
	font-weight: 800;
	font-size: 3.5rem;
	color: #ffffff;
	margin-top: 3%;
	font-family: 'Montserrat', sans-serif;
}

.interiorSignup input {
	width: 40%;
	padding: 14px;
	margin-top: 3%;
	background-color: #236490;
	border: none;
	border: 1px solid #ffffff;
	color: #ffffff;
	box-shadow: 0px 0px 12px -7px #ffffff;
	border-radius: 5px 5px 5px 5px;
}

.interiorSignup input::-webkit-input-placeholder {
	color: #ffffff;
}

.interiorSignup input::-moz-placeholder {
	color: #ffffff;
}

.interiorSignup input::-ms-input-placeholder {
	color: #ffffff;
}

.interiorSignup input::placeholder {
	color: #ffffff;
}

.interiorSignup input:focus {
	outline-offset: 0px !important;
	outline: none !important;
	border: 1px solid #91259f !important;
	box-shadow: 0 0 3px #91259f !important;
	-moz-box-shadow: 0 0 3px v !important;
	-webkit-box-shadow: 0 0 3px #91259f !important;
}

.interiorSignup p {
	color: #ffffff;
	width: 70%;
	margin: 0 auto;
	margin-top: 5%;
	font-size: 18px;
}









#clouds {
	/* padding: 100px 0;
	background: #66ccff;
	background: -webkit-linear-gradient(top, #6DD3E7 20%, #fff 70%);
	background: linear-gradient(top, #6DD3E7 20%, #fff 70%);
	background: -moz-linear-gradient(top, #6DD3E7 20%, #fff 70%);
	height: 100vh; */
}

/* .cloud {
	width: 250px;
	height: 60px;
	background-color: white;
	border-radius: 200px;
	-moz-border-radius: 200px;
	-webkit-border-radius: 200px;
	position: relative;
	
	-moz-box-shadow: 9px -7px 0 0 #f7e7eb inset;
	-webkit-box-shadow: 9px -7px 0 0 #f7e7eb inset;
	box-shadow: 9px -7px 0 0 #f7e7eb inset;
} */

.cloud {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEoAAAAqCAYAAAAUJM0rAAACFUlEQVRo3u3aPSwDYRzH8UZEbAYiYrDYjLZGpIPBJLGwSKwi0k1iYBKDdFJsEiMxSZgsFZJGiGjqJWEhSKuaNKRUG8r5PfI8SXOud72+PH2eu2f47Ndv7+V5/nee/G6bh5MWmIV9iEMWvkGjfiADD7AHk9BU6+PSNK0kPAINwwXkC6KU6gvC4HVyKB/clhHHCDnbTqHbSaEaYIv+OK3KyBk274RQrXBXg0B6B/QPkTIUiZTiEIm5gkbZQjXTJ5nG2ZFsocJ1iMQEZQk1VcdIGl2L9Yoeitwj3uocirgRPVRAgEjMoMihkgKFStIzK063QVHYhKF6h/IKFMlKGpaNlhM8Qq1JFIp5gQHeoSIShmJPST/PUElJQ7FN9hivUGmJQxE56OQRKiN5qL/NNY9QKQeEIpdgR7VC9cEqHeGeUyEHXHrMUiWhyKx6BV4dEsNMtNxQI4Ls33h5LidUoEYjXKEXoXZDLbgsEBOzE8qne8fmJhE7oWIujUQcwhyMmy0VSKRRF0cyWlclYMYo1LEKZOgRugpDpVWUot5ZLI8LlwN23bNQKoY1vwpVmmsS6kOFsPRJQl2qENZIqGkVwnp9xb5nUksEcxm2hZlQMcy3OYXTgx0VpOi2pl8/jwqpMP9sF5twBl08ctE7I/dws5l5D5y4OBj51HvdzluYdlik0RL06cjkKpSlB1Span5x/AQb5Hfrxyy/oU5ISeVw53AAAAAASUVORK5CYII=);
  }

.cloud:before, .cloud:after {
	content: '';
	position: absolute;
	background-color: white;
	width: 120px;
	height:120px;
	top: -75px;
	
	border-radius: 100px;
	-moz-border-radius: 100px;
	-webkit-border-radius: 100px;
	
	transform: rotate(30deg);
	-moz-transform: rotate(30deg);
	-webkit-transform: rotate(30deg);
	box-shadow: 10px 3px 0 0 #f7e7eb inset;
}

.cloud:after {
	width: 90px;
	height: 90px;
	top: -45px;
	right: 25px;
}

.cloud:before {
	left: 25px;
}

.x1 {
	-webkit-animation: moveclouds 45s linear infinite;
	-moz-animation: moveclouds 45s linear infinite;
	-o-animation: moveclouds 45s linear infinite;
}

@-webkit-keyframes moveclouds {
	0% {margin-left: 1000px;}
	100% {margin-left: -1000px;}
}






.bg {
	-webkit-animation: slide 120s ease-in-out infinite alternate;
	        animation: slide 120s ease-in-out infinite alternate;
	background-image: -webkit-linear-gradient(150deg, #232f3e 50%, #00dddd 50%);
	background-image: linear-gradient(-60deg, #232f3e 50%, #00dddd 50%);
	bottom: 0;
	left: -50%;
	opacity: .5;
	position: absolute;
	right: -50%;
	top: 0;
	z-index: 999;
}

.bg2 {
	animation-direction:alternate-reverse;
	-webkit-animation-duration:64s;
	        animation-duration:64s;
}

.bg3 {
	-webkit-animation-duration:80s;
	        animation-duration:80s;
}

.bg4 {
	-webkit-animation-duration:32s;
	        animation-duration:32s;
}

.bg5 {
	-webkit-animation-duration:16s;
	        animation-duration:16s;
}

@-webkit-keyframes slide {
	0% {
		-webkit-transform:translateX(-25%);
		        transform:translateX(-25%);
	}
	100% {
		-webkit-transform:translateX(25%);
		        transform:translateX(25%);
	}
}

@keyframes slide {
	0% {
		-webkit-transform:translateX(-25%);
		        transform:translateX(-25%);
	}
	100% {
		-webkit-transform:translateX(25%);
		        transform:translateX(25%);
	}
}

.formContainer input {
	border: none;
	border-left: 4px solid #00dddd;
	box-shadow: 6px 6px 15px #cccccc;
	margin-top: 0;
	margin-left: 0;
	z-index: 9999;
	overflow: visible;
	background: white;
	position: absolute;
}





main.gallery {
	padding: 0;
	margin: 0;
}

ul, li {
	list-style: none;
}

.techRow .col {
	padding: 0;
}

.gallery > h1 {
	font-size: 3rem;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	margin-bottom: 1rem;
	color: #333333;
	font-weight: 100;
}

.gallery > .gallery__list {
	display: grid;
	grid-auto-flow: dense;
	grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
	grid-template-rows: repeat(4, 100px);
	grid-gap: 0rem;
	/* grid-auto-flow: dense; */
	padding: 0;
	margin: 0;
	/* max-height: 300px; */
	overflow: hidden;
	width: 200%;
	/* animation: moveSlideshow 15s linear infinite; */
}

@-webkit-keyframes moveSlideshow {
	/* 0% {
		margin-left: 0px;
	  }
	  100% {
		margin-left: -2125px;
	  } */
	100% { 
		-webkit-transform: translateX(-66.6666%); 
		        transform: translateX(-66.6666%);
	}
}

@keyframes moveSlideshow {
	/* 0% {
		margin-left: 0px;
	  }
	  100% {
		margin-left: -2125px;
	  } */
	100% { 
		-webkit-transform: translateX(-66.6666%); 
		        transform: translateX(-66.6666%);
	}
}

@media screen and (max-width: 800px) {
	.gallery > .gallery__list {
		max-height: 200px;
	}
}

@media screen and (min-width : 801px) and (max-width : 1400px) {
	.gallery > .gallery__list {
		max-height: 200px;
	}
}

@media screen and (min-width: 1401px) {
	.gallery > .gallery__list {
		max-height: 200px;
	}
}

/* .gallery > .gallery__list > li:nth-child(4n) {
	grid-column: span 2;
	grid-row: span 2;
}

.gallery > .gallery__list > li:nth-child(8n) {
	grid-column: span 3;
	grid-row: span 3;
} */

.gallery > .gallery__list > li > figure {
	height: 100%;
	margin: 0;
}

.gallery > .gallery__list > li > figure > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
	background-color: transparent;
	width: 100%;
	height: 100%;
	-webkit-perspective: 1000px;
	        perspective: 1000px; /* Remove this if you don't want the 3D effect */
	display: inline-block;
	margin: 0;
}

/* This container is needed to position the front and back side */
.flip-card-inner {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
	-webkit-transition: -webkit-transform 0.2s;
	transition: -webkit-transform 0.2s;
	transition: transform 0.2s;
	transition: transform 0.2s, -webkit-transform 0.2s;
	-webkit-transform-style: preserve-3d;
	        transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
	-webkit-transition: -webkit-transform 0.2s ease-in;
	-webkit-transform: rotateY(180deg);
	        transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
	position: absolute;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	background-color: #1c2633;
	/* border: 1px solid white; */
	background-color: #1c2633;
	color: #ffffff;
	font-size: 60px;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
	
}

.flip-card-front i, .flip-card-back i {
	line-height: 100px;
}

/* Style the back side */
.flip-card-back {
	background-color: #1c2633;
	/* color: #FF1D06; */
	/* color: #3f2679; */
	color: #39b1fe;
	-webkit-transform: rotateY(180deg);
	        transform: rotateY(180deg);
	/* text-shadow: 0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #15f4ee, 0 0 30px #15f4ee, 0 0 40px #15f4ee, 0 0 55px #15f4ee, 0 0 75px #15f4ee, 2px 2px 2px rgba(21, 244, 238, 0); */
}






.towerRow {
	height: 40vh;
	overflow: hidden;
}






.face {
	overflow: hidden;
	-webkit-transform-origin: 0 0;
	        transform-origin: 0 0;
	background-position: center;
	background-size: 100% 100% !important;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
}

.scene, .shape, .face {
	position: absolute;
	width: 200px;
	height: 200px;
	-webkit-transform-style: preserve-3d;
	        transform-style: preserve-3d;
}

.scene {
	font-size: 12px;
	top: 100%;
	left: 70%;
	width: 80em;
	height: 120em;
	margin: -40em 0 0 -40em;
	-webkit-transform: rotateX(-33deg) rotateY(-141deg);
	        transform: rotateX(-33deg) rotateY(-141deg);
}

.shape {
	top: 50%;
	left: 50%;
	width: 0;
	height: 0;
	-webkit-transform-origin: 50%;
	        transform-origin: 50%;
}
.shape .ft {
	width: 100%;
	height: 100%;
}
.shape .bk {
	margin-top: -1px;
	left: 100%;
	width: 100%;
	height: 100%;
}
.shape .rt {
	-webkit-transform: rotateY(-90deg) translateX(-50%);
	        transform: rotateY(-90deg) translateX(-50%);
}
.shape .lt {
	margin-top: -1px;
	-webkit-transform: rotateY(90deg) translateX(-50%);
	        transform: rotateY(90deg) translateX(-50%);
}
.shape .tp {
	-webkit-transform: rotateX(90deg) translateY(-50%);
	        transform: rotateX(90deg) translateY(-50%);
}
.shape .bm {
	-webkit-transform: rotateX(-90deg) translateY(-50%);
	        transform: rotateX(-90deg) translateY(-50%);
}
.shape .lt {
	left: 100%;
}
.shape .bm {
	top: 100%;
}

.tower-main {
	width: 30em;
	height: 4em;
	-webkit-transform: translate3D(0em, -11em, 0.1em) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
	        transform: translate3D(0em, -11em, 0.1em) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
	opacity: 1;
}
.tower-main .shadow-ft {
	width: 100%;
	height: 14em;
	-webkit-transform: translateZ(-6em) translateY(15em) rotateX(90deg);
	        transform: translateZ(-6em) translateY(15em) rotateX(90deg);
	opacity: 0.5;
	/* background: linear-gradient(to top, #F951FD, rgba(249, 81, 253, 0)); */
}
.tower-main > .ft, .tower-main > .bk {
	width: 30em;
	margin-left: 0;
}
.tower-main .ft {
	-webkit-transform: translateZ(4.5em);
	        transform: translateZ(4.5em);
}
.tower-main > .bk {
	-webkit-transform: translateZ(-15em) rotateY(180deg);
	        transform: translateZ(-15em) rotateY(180deg);
	/* background-color: #A470F1; */
	/* background-image: linear-gradient(to bottom, #716B82, #4A445A); */
	background-image: -webkit-gradient(linear, left top, left bottom, from(#303d4e), to(#181f29));
	background-image: -webkit-linear-gradient(top, #303d4e, #181f29);
	background-image: linear-gradient(to bottom, #303d4e, #181f29);
}
.tower-main .rt {
	width: 30em;
}
.tower-main .lt {
	width: 30em;
	height: 4em;
	/* background-image: linear-gradient(to bottom, #4A445A, #343040); */
	background-image: -webkit-gradient(linear, left top, left bottom, from(#252b33), to(#1b1823));
	background-image: -webkit-linear-gradient(top, #252b33, #1b1823);
	background-image: linear-gradient(to bottom, #252b33, #1b1823)
}
.tower-main .rt {
	height: 22em;
}
.tower-main .tp {
	width: 30em;
	height: 30em;
	/* background: #A16DF1; */
	text-align: center;
	/* background: #443e52; */
	background: #1c2633;
	color: #ffffff;
	text-shadow: 0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #15f4ee, 0 0 30px #15f4ee, 0 0 40px #15f4ee, 0 0 55px #15f4ee, 0 0 75px #15f4ee, 2px 2px 2px rgba(21, 244, 238, 0);
}

	.main-tower-enter .tp {
		text-shadow: 0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #15f4ee, 0 0 30px #15f4ee, 0 0 40px #15f4ee, 0 0 55px #15f4ee, 0 0 75px #15f4ee, 2px 2px 2px rgba(21, 244, 238, 0);
	}

.tower-main .tp p {
	text-align: center;
	position: relative;
	top: 12%;
	-webkit-transform:rotate(-180deg);
	        transform:rotate(-180deg);
	/* -ms-transform: translateY(-50%); */
	/* -webkit-transform: translateY(-50%); */
	/* transform: translateY(-50%); */
	font-size: 15em;
}

.main-tower-enter .face {
	opacity: 1;
	-webkit-animation-name: fadeInOpacity;
	        animation-name: fadeInOpacity;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
	-webkit-animation-timing-function: ease-in;
	        animation-timing-function: ease-in;
	-webkit-animation-duration: 3s;
	        animation-duration: 3s;
}

.main-tower-enter {
	margin: -19em 0 0 -4.5em;
	-webkit-transform: translateX(-400%);
	        transform: translateX(-400%);
	-webkit-animation: animationEnter 6s linear infinite;
	        animation: animationEnter 6s linear infinite;
}

.main-tower-8 {
	margin: -56em 0 0 -4.5em;
	-webkit-animation: animation-8 6s ease infinite;
	        animation: animation-8 6s ease infinite;
}

.main-tower-7 {
	margin: -52em 0 0 -4.5em;
	-webkit-animation: animation-7 6s ease infinite;
	        animation: animation-7 6s ease infinite;
}

.main-tower-6 {
	margin: -48em 0 0 -4.5em;
	-webkit-animation: animation-6 6s ease infinite;
	        animation: animation-6 6s ease infinite;
}

.main-tower-5 {
	margin: -44em 0 0 -4.5em;
	-webkit-animation: animation-5 6s ease infinite;
	        animation: animation-5 6s ease infinite;
}

.main-tower-4 {
	margin: -40em 0 0 -4.5em;
	-webkit-animation: animation-4 6s ease infinite;
	        animation: animation-4 6s ease infinite;
}

.main-tower-3 {
	margin: -36em 0 0 -4.5em;
	-webkit-animation: animation-3 6s ease infinite;
	        animation: animation-3 6s ease infinite;
}

.main-tower-2 {
	margin: -32em 0 0 -4.5em;
	-webkit-animation: animation-2 6s ease infinite;
	        animation: animation-2 6s ease infinite;
}

.main-tower-1 {
	margin: -28em 0 0 -4.5em;
	-webkit-animation: animation-1 6s ease infinite;
	        animation: animation-1 6s ease infinite;
}

.main-tower0 {
	margin: -24em 0 0 -4.5em;
	-webkit-animation: animation0 6s ease infinite;
	        animation: animation0 6s ease infinite;
}

.main-tower1 {
	margin: -20em 0 0 -4.5em;
	-webkit-animation: animation1 6s ease infinite;
	        animation: animation1 6s ease infinite;
}

.main-tower2 {
	margin: -16em 0 0 -4.5em;
	-webkit-animation: animation2 6s ease infinite;
	        animation: animation2 6s ease infinite;
}

.main-tower3 {
	margin: -12em 0 0 -4.5em;
	-webkit-animation: animation3 6s ease infinite;
	        animation: animation3 6s ease infinite;
}

.main-tower4 {
	margin: -8em 0 0 -4.5em;
	-webkit-animation: animation4 6s ease infinite;
	        animation: animation4 6s ease infinite;
}

.main-tower5 {
	margin: -4em 0 0 -4.5em;
	-webkit-animation: animation5 6s ease infinite;
	        animation: animation5 6s ease infinite;
}

.main-tower6 {
	margin: 0em 0 0 -4.5em;
	-webkit-animation: animation6 6s ease infinite;
	        animation: animation6 6s ease infinite;
}

.main-tower7 {
	margin: 4em 0 0 -4.5em;
	-webkit-animation: animation7 6s ease infinite;
	        animation: animation7 6s ease infinite;
}

.main-tower8 {
	margin: 8em 0 0 -4.5em;
	-webkit-animation: animation8 6s ease infinite;
	        animation: animation8 6s ease infinite;
}

.main-tower9 {
	margin: 12em 0 0 -4.5em;
	-webkit-animation: animation9 6s ease infinite;
	        animation: animation9 6s ease infinite;
}

.main-tower10 {
	margin: 16em 0 0 -4.5em;
	-webkit-animation: animation10 6s ease infinite;
	        animation: animation10 6s ease infinite;
}

.main-tower11 {
	margin: 20em 0 0 -4.5em;
	-webkit-animation: animation11 6s ease infinite;
	        animation: animation11 6s ease infinite;
}

.main-tower12 {
	margin: 24em 0 0 -4.5em;
	-webkit-animation: animation12 6s ease infinite;
	        animation: animation12 6s ease infinite;
}

.main-tower13 {
	margin: 28em 0 0 -4.5em;
	-webkit-animation: animation13 6s ease infinite;
	        animation: animation13 6s ease infinite;
}

@-webkit-keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-webkit-keyframes animationEnter {
	0% {
		-webkit-transform: translateX(-400%);
		        transform: translateX(-400%);
	}
	85% {
		-webkit-transform: translateX(0%);
		        transform: translateX(0%);
	}
	100% {
		-webkit-transform: translateX(0%);
		        transform: translateX(0%);
	}
}
@keyframes animationEnter {
	0% {
		-webkit-transform: translateX(-400%);
		        transform: translateX(-400%);
	}
	85% {
		-webkit-transform: translateX(0%);
		        transform: translateX(0%);
	}
	100% {
		-webkit-transform: translateX(0%);
		        transform: translateX(0%);
	}
}

@-webkit-keyframes animation-8 {
	20% {
		margin: -56em 0 0 -4.5em;
	}
	60% {
		margin: -127em 0 0 -4.5em;
	}
	100% {
		margin: -60em 0 0 -4.5em;
	}
}

@keyframes animation-8 {
	20% {
		margin: -56em 0 0 -4.5em;
	}
	60% {
		margin: -127em 0 0 -4.5em;
	}
	100% {
		margin: -60em 0 0 -4.5em;
	}
}
@-webkit-keyframes animation-7 {
	20% {
		margin: -52em 0 0 -4.5em;
	}
	60% {
		margin: -118em 0 0 -4.5em;
	}
	100% {
		margin: -56em 0 0 -4.5em;
	}
}
@keyframes animation-7 {
	20% {
		margin: -52em 0 0 -4.5em;
	}
	60% {
		margin: -118em 0 0 -4.5em;
	}
	100% {
		margin: -56em 0 0 -4.5em;
	}
}
@-webkit-keyframes animation-6 {
	20% {
		margin: -48em 0 0 -4.5em;
	}
	60% {
		margin: -109em 0 0 -4.5em;
	}
	100% {
		margin: -52em 0 0 -4.5em;
	}
}
@keyframes animation-6 {
	20% {
		margin: -48em 0 0 -4.5em;
	}
	60% {
		margin: -109em 0 0 -4.5em;
	}
	100% {
		margin: -52em 0 0 -4.5em;
	}
}
@-webkit-keyframes animation-5 {
	20% {
		margin: -44em 0 0 -4.5em;
	}
	60% {
		margin: -100em 0 0 -4.5em;
	}
	100% {
		margin: -48em 0 0 -4.5em;
	}
}
@keyframes animation-5 {
	20% {
		margin: -44em 0 0 -4.5em;
	}
	60% {
		margin: -100em 0 0 -4.5em;
	}
	100% {
		margin: -48em 0 0 -4.5em;
	}
}
@-webkit-keyframes animation-4 {
	20% {
		margin: -40em 0 0 -4.5em;
	}
	60% {
		margin: -91em 0 0 -4.5em;
	}
	100% {
		margin: -44em 0 0 -4.5em;
	}
}
@keyframes animation-4 {
	20% {
		margin: -40em 0 0 -4.5em;
	}
	60% {
		margin: -91em 0 0 -4.5em;
	}
	100% {
		margin: -44em 0 0 -4.5em;
	}
}
@-webkit-keyframes animation-3 {
	20% {
		margin: -36em 0 0 -4.5em;
	}
	60% {
		margin: -82em 0 0 -4.5em;
	}
	100% {
		margin: -40em 0 0 -4.5em;
	}
}
@keyframes animation-3 {
	20% {
		margin: -36em 0 0 -4.5em;
	}
	60% {
		margin: -82em 0 0 -4.5em;
	}
	100% {
		margin: -40em 0 0 -4.5em;
	}
}
@-webkit-keyframes animation-2 {
	20% {
		margin: -32em 0 0 -4.5em;
	}
	60% {
		margin: -73em 0 0 -4.5em;
	}
	100% {
		margin: -36em 0 0 -4.5em;
	}
}
@keyframes animation-2 {
	20% {
		margin: -32em 0 0 -4.5em;
	}
	60% {
		margin: -73em 0 0 -4.5em;
	}
	100% {
		margin: -36em 0 0 -4.5em;
	}
}
@-webkit-keyframes animation-1 {
	20% {
		margin: -28em 0 0 -4.5em;
	}
	60% {
		margin: -64em 0 0 -4.5em;
	}
	100% {
		margin: -32em 0 0 -4.5em;
	}
}
@keyframes animation-1 {
	20% {
		margin: -28em 0 0 -4.5em;
	}
	60% {
		margin: -64em 0 0 -4.5em;
	}
	100% {
		margin: -32em 0 0 -4.5em;
	}
}
@-webkit-keyframes animation0 {
	20% {
		margin: -24em 0 0 -4.5em;
	}
	60% {
		margin: -55em 0 0 -4.5em;
	}
	100% {
		margin: -28em 0 0 -4.5em;
	}
}
@keyframes animation0 {
	20% {
		margin: -24em 0 0 -4.5em;
	}
	60% {
		margin: -55em 0 0 -4.5em;
	}
	100% {
		margin: -28em 0 0 -4.5em;
	}
}
@-webkit-keyframes animation1 {
	20% {
		margin: -20em 0 0 -4.5em;
	}
	60% {
		margin: -46em 0 0 -4.5em;
	}
	100% {
		margin: -24em 0 0 -4.5em;
	}
}
@keyframes animation1 {
	20% {
		margin: -20em 0 0 -4.5em;
	}
	60% {
		margin: -46em 0 0 -4.5em;
	}
	100% {
		margin: -24em 0 0 -4.5em;
	}
}
@-webkit-keyframes animation2 {
	20% {
		margin: -16em 0 0 -4.5em;
	}
	60% {
		margin: -37em 0 0 -4.5em;
	}
	100% {
		margin: -20em 0 0 -4.5em;
	}
}
@keyframes animation2 {
	20% {
		margin: -16em 0 0 -4.5em;
	}
	60% {
		margin: -37em 0 0 -4.5em;
	}
	100% {
		margin: -20em 0 0 -4.5em;
	}
}
@-webkit-keyframes animation3 {
	20% {
		margin: -12em 0 0 -4.5em;
	}
	60% {
		margin: -28em 0 0 -4.5em;
	}
	100% {
		margin: -16em 0 0 -4.5em;
	}
}
@keyframes animation3 {
	20% {
		margin: -12em 0 0 -4.5em;
	}
	60% {
		margin: -28em 0 0 -4.5em;
	}
	100% {
		margin: -16em 0 0 -4.5em;
	}
}
@-webkit-keyframes animation4 {
	20% {
		margin: -8em 0 0 -4.5em;
	}
	60% {
		margin: -19em 0 0 -4.5em;
	}
	100% {
		margin: -12em 0 0 -4.5em;
	}
}
@keyframes animation4 {
	20% {
		margin: -8em 0 0 -4.5em;
	}
	60% {
		margin: -19em 0 0 -4.5em;
	}
	100% {
		margin: -12em 0 0 -4.5em;
	}
}
@-webkit-keyframes animation5 {
	20% {
		margin: -4em 0 0 -4.5em;
	}
	60% {
		margin: 1em 0 0 -4.5em;
	}
	100% {
		margin: -4em 0 0 -4.5em;
	}
}
@keyframes animation5 {
	20% {
		margin: -4em 0 0 -4.5em;
	}
	60% {
		margin: 1em 0 0 -4.5em;
	}
	100% {
		margin: -4em 0 0 -4.5em;
	}
}
@-webkit-keyframes animation6 {
	20% {
		margin: 0em 0 0 -4.5em;
	}
	60% {
		margin: 10em 0 0 -4.5em;
	}
	100% {
		margin: 0em 0 0 -4.5em;
	}
}
@keyframes animation6 {
	20% {
		margin: 0em 0 0 -4.5em;
	}
	60% {
		margin: 10em 0 0 -4.5em;
	}
	100% {
		margin: 0em 0 0 -4.5em;
	}
}
@-webkit-keyframes animation7 {
	20% {
		margin: 4em 0 0 -4.5em;
	}
	60% {
		margin: 19em 0 0 -4.5em;
	}
	100% {
		margin: 4em 0 0 -4.5em;
	}
}
@keyframes animation7 {
	20% {
		margin: 4em 0 0 -4.5em;
	}
	60% {
		margin: 19em 0 0 -4.5em;
	}
	100% {
		margin: 4em 0 0 -4.5em;
	}
}
@-webkit-keyframes animation8 {
	20% {
		margin: 8em 0 0 -4.5em;
	}
	60% {
		margin: 28em 0 0 -4.5em;
	}
	100% {
		margin: 8em 0 0 -4.5em;
	}
}
@keyframes animation8 {
	20% {
		margin: 8em 0 0 -4.5em;
	}
	60% {
		margin: 28em 0 0 -4.5em;
	}
	100% {
		margin: 8em 0 0 -4.5em;
	}
}
@-webkit-keyframes animation9 {
	20% {
		margin: 12em 0 0 -4.5em;
	}
	60% {
		margin: 37em 0 0 -4.5em;
	}
	100% {
		margin: 12em 0 0 -4.5em;
	}
}
@keyframes animation9 {
	20% {
		margin: 12em 0 0 -4.5em;
	}
	60% {
		margin: 37em 0 0 -4.5em;
	}
	100% {
		margin: 12em 0 0 -4.5em;
	}
}
@-webkit-keyframes animation10 {
	20% {
		margin: 16em 0 0 -4.5em;
	}
	60% {
		margin: 46em 0 0 -4.5em;
	}
	100% {
		margin: 16em 0 0 -4.5em;
	}
}
@keyframes animation10 {
	20% {
		margin: 16em 0 0 -4.5em;
	}
	60% {
		margin: 46em 0 0 -4.5em;
	}
	100% {
		margin: 16em 0 0 -4.5em;
	}
}
@-webkit-keyframes animation11 {
	20% {
		margin: 20em 0 0 -4.5em;
	}
	60% {
		margin: 55em 0 0 -4.5em;
	}
	100% {
		margin: 20em 0 0 -4.5em;
	}
}
@keyframes animation11 {
	20% {
		margin: 20em 0 0 -4.5em;
	}
	60% {
		margin: 55em 0 0 -4.5em;
	}
	100% {
		margin: 20em 0 0 -4.5em;
	}
}
@-webkit-keyframes animation12 {
	20% {
		margin: 24em 0 0 -4.5em;
	}
	60% {
		margin: 64em 0 0 -4.5em;
	}
	100% {
		margin: 24em 0 0 -4.5em;
	}
}
@keyframes animation12 {
	20% {
		margin: 24em 0 0 -4.5em;
	}
	60% {
		margin: 64em 0 0 -4.5em;
	}
	100% {
		margin: 24em 0 0 -4.5em;
	}
}
@-webkit-keyframes animation13 {
	20% {
		margin: 28em 0 0 -4.5em;
	}
	60% {
		margin: 73em 0 0 -4.5em;
	}
	100% {
		margin: 28em 0 0 -4.5em;
	}
}
@keyframes animation13 {
	20% {
		margin: 28em 0 0 -4.5em;
	}
	60% {
		margin: 73em 0 0 -4.5em;
	}
	100% {
		margin: 28em 0 0 -4.5em;
	}
}














.demo-1 {
	--color-text: #fff;
	--color-bg: #000;
	--color-link: #f9d77e;
	--color-link-hover: #fff;
	--color-info: #efc453;
	--glitch-width: 100vw;
	--glitch-height: 100vh;
	--gap-horizontal: 10px;
	--gap-vertical: 5px;
	--time-anim: 4s;
	--delay-anim: 2s;
	--blend-mode-1: none;
	--blend-mode-2: none;
	--blend-mode-3: none;
	--blend-mode-4: none;
	--blend-mode-5: overlay;
	--blend-color-1: transparent;
	--blend-color-2: transparent;
	--blend-color-3: transparent;
	--blend-color-4: transparent;
	--blend-color-5: #af4949;
}

.imgloaded .content__title,
.imgloaded .content__text {
	-webkit-animation-name: glitch-anim-text;
	        animation-name: glitch-anim-text;
	-webkit-animation-duration: var(--time-anim);
	        animation-duration: var(--time-anim);
	-webkit-animation-timing-function: linear;
	        animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
}

.content__title {
	font-size: 12vw;
	margin: 35vh 0 0 0;
	position: relative;
	font-family: 'Playfair Display', serif;
	-webkit-animation-delay: calc(var(--delay-anim) + var(--time-anim) * 0.2);
	        animation-delay: calc(var(--delay-anim) + var(--time-anim) * 0.2);
}

.content__text {
	font-family: 'IM Fell English', serif;
	position: absolute;
	font-size: 1.5em;
	top: 4em;
	right: 10vw;
	max-width: 500px;
	text-align: right;
	font-weight: 400;
	-webkit-animation-delay: calc(var(--delay-anim) + var(--time-anim) * 0.25);
	        animation-delay: calc(var(--delay-anim) + var(--time-anim) * 0.25);
}

@media screen and (max-width: 55em) {
	.content__text {
		position: relative;
		right: auto;
		top: auto;
		font-size: 1.25em;
		padding: 0 1em;
		text-align: center;
	}
}

/* Glitch styles */
.glitch {
	position: absolute;
	top: 0;
	left: 0;
	width: var(--glitch-width);
	height: var(--glitch-height);
	overflow: hidden;
}

.glitch__img {
	position: absolute;
	top: calc(-1 * var(--gap-vertical));
	left: calc(-1 * var(--gap-horizontal));
	width: calc(100% + var(--gap-horizontal) * 2);
	height: calc(100% + var(--gap-vertical) * 2);
	background: url(/static/media/bgline.e6c5a28c.jpg) no-repeat 50% 0;
	background-color: var(--blend-color-1);
	background-size: cover;
	-webkit-transform: translate3d(0,0,0);
	        transform: translate3d(0,0,0);
	background-blend-mode: var(--blend-mode-1);
}

.glitch__img:nth-child(n+2) {
	opacity: 0;
}

.imgloaded .glitch__img:nth-child(n+2) {
	-webkit-animation-duration: var(--time-anim);
	        animation-duration: var(--time-anim);
	-webkit-animation-delay: var(--delay-anim);
	        animation-delay: var(--delay-anim);
	-webkit-animation-timing-function: linear;
	        animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
}

.imgloaded .glitch__img:nth-child(2) {
	background-color: var(--blend-color-2);
	background-blend-mode: var(--blend-mode-2);
	-webkit-animation-name: glitch-anim-1;
	        animation-name: glitch-anim-1;
}

.imgloaded .glitch__img:nth-child(3) {
	background-color: var(--blend-color-3);
	background-blend-mode: var(--blend-mode-3);
	-webkit-animation-name: glitch-anim-2;
	        animation-name: glitch-anim-2;
}

.imgloaded .glitch__img:nth-child(4) {
	background-color: var(--blend-color-4);
	background-blend-mode: var(--blend-mode-4);
	-webkit-animation-name: glitch-anim-3;
	        animation-name: glitch-anim-3;
}

.imgloaded .glitch__img:nth-child(5) {
	background-color: var(--blend-color-5);
	background-blend-mode: var(--blend-mode-5);
	-webkit-animation-name: glitch-anim-flash;
	        animation-name: glitch-anim-flash;
}

/* Animations */

@-webkit-keyframes glitch-anim-1 {
	0% { 
		opacity: 1;
		-webkit-transform: translate3d(var(--gap-horizontal),0,0);
		        transform: translate3d(var(--gap-horizontal),0,0);
		-webkit-clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
		clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
	}
	0.5% {
		-webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
		clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
	}
	1% {
		-webkit-clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
		clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
	}
	1.5% {
		-webkit-clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
		clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
	}
	2% {
		-webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
		clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
	}
	2.5% {
		-webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
		clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
	}
	3% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
		clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
	}
	3.5% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
		clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
	}
	4% {
		-webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
		clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
	}
	4.5% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
		clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
	}
	5% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
		clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
	}
	5.49% {
		opacity: 1;
		-webkit-transform: translate3d(var(--gap-horizontal),0,0);
		        transform: translate3d(var(--gap-horizontal),0,0);
	}
	5%, 100% {
		opacity: 0;
		-webkit-transform: translate3d(0,0,0);
		        transform: translate3d(0,0,0);
		-webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
		clip-path: polygon(0 0, 0 0, 0 0, 0 0);
	}
}

@keyframes glitch-anim-1 {
	0% { 
		opacity: 1;
		-webkit-transform: translate3d(var(--gap-horizontal),0,0);
		        transform: translate3d(var(--gap-horizontal),0,0);
		-webkit-clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
		clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
	}
	0.5% {
		-webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
		clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
	}
	1% {
		-webkit-clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
		clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
	}
	1.5% {
		-webkit-clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
		clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
	}
	2% {
		-webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
		clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
	}
	2.5% {
		-webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
		clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
	}
	3% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
		clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
	}
	3.5% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
		clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
	}
	4% {
		-webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
		clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
	}
	4.5% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
		clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
	}
	5% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
		clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
	}
	5.49% {
		opacity: 1;
		-webkit-transform: translate3d(var(--gap-horizontal),0,0);
		        transform: translate3d(var(--gap-horizontal),0,0);
	}
	5%, 100% {
		opacity: 0;
		-webkit-transform: translate3d(0,0,0);
		        transform: translate3d(0,0,0);
		-webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
		clip-path: polygon(0 0, 0 0, 0 0, 0 0);
	}
}

@-webkit-keyframes glitch-anim-2 {
	0% { 
		opacity: 1;
		-webkit-transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0);
		        transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0);
		-webkit-clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
		clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
	}
	3% {
		-webkit-clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
		clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
	}
	5% {
		-webkit-clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
		clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
	}
	7% {
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
		clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
	}
	9% {
		-webkit-clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
		clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
	}
	11% {
		-webkit-clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
		clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
	}
	13% {
		-webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
		clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
	}
	15% {
		-webkit-clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
		clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
	}
	17% {
		-webkit-clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
		clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
	}
	19% {
		-webkit-clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
		clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
	}
	20% {
		-webkit-clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
		clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
	}
	21.9% {
		opacity: 1;
		-webkit-transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0);
		        transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0);
	}
	22%, 100% {
		opacity: 0;
		-webkit-transform: translate3d(0,0,0);
		        transform: translate3d(0,0,0);
		-webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
		clip-path: polygon(0 0, 0 0, 0 0, 0 0);
	}
}

@keyframes glitch-anim-2 {
	0% { 
		opacity: 1;
		-webkit-transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0);
		        transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0);
		-webkit-clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
		clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
	}
	3% {
		-webkit-clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
		clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
	}
	5% {
		-webkit-clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
		clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
	}
	7% {
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
		clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
	}
	9% {
		-webkit-clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
		clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
	}
	11% {
		-webkit-clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
		clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
	}
	13% {
		-webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
		clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
	}
	15% {
		-webkit-clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
		clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
	}
	17% {
		-webkit-clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
		clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
	}
	19% {
		-webkit-clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
		clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
	}
	20% {
		-webkit-clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
		clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
	}
	21.9% {
		opacity: 1;
		-webkit-transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0);
		        transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0);
	}
	22%, 100% {
		opacity: 0;
		-webkit-transform: translate3d(0,0,0);
		        transform: translate3d(0,0,0);
		-webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
		clip-path: polygon(0 0, 0 0, 0 0, 0 0);
	}
}

@-webkit-keyframes glitch-anim-3 {
	0% { 
		opacity: 1;
		-webkit-transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1,-1,1);
		        transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1,-1,1);
		-webkit-clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
		clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
	}
	0.5% {
		-webkit-clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
		clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
	}
	1% {
		-webkit-clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
		clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
	}
	1.5% {
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
		clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
	}
	2% {
		-webkit-clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
		clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
	}
	4% {
		-webkit-clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
		clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
	}
	4.5% {
		-webkit-clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
		clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
	}
	5% {
		-webkit-clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
		clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
	}
	6% {
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
		clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
	}
	7% {
		-webkit-clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
		clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
	}
	9.5% {
		-webkit-clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
		clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
	}
	10% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
		clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
	}
	11% {
		-webkit-clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
		clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
	}
	12% {
		-webkit-clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
		clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
	}
	12.5% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
		clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
	}
	13% {
		-webkit-clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
		clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
	}
	14% {
		-webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
		clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
	}
	15% {
		-webkit-clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
		clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
	}
	16% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
		clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
	}
	18.9% {
		opacity: 1;
		-webkit-transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1,-1,1);
		        transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1,-1,1);
	}
	19%, 100% {
		opacity: 0;
		-webkit-transform: translate3d(0,0,0);
		        transform: translate3d(0,0,0);
		-webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
		clip-path: polygon(0 0, 0 0, 0 0, 0 0);
	}
}

@keyframes glitch-anim-3 {
	0% { 
		opacity: 1;
		-webkit-transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1,-1,1);
		        transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1,-1,1);
		-webkit-clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
		clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
	}
	0.5% {
		-webkit-clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
		clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
	}
	1% {
		-webkit-clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
		clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
	}
	1.5% {
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
		clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
	}
	2% {
		-webkit-clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
		clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
	}
	4% {
		-webkit-clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
		clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
	}
	4.5% {
		-webkit-clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
		clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
	}
	5% {
		-webkit-clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
		clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
	}
	6% {
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
		clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
	}
	7% {
		-webkit-clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
		clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
	}
	9.5% {
		-webkit-clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
		clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
	}
	10% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
		clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
	}
	11% {
		-webkit-clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
		clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
	}
	12% {
		-webkit-clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
		clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
	}
	12.5% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
		clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
	}
	13% {
		-webkit-clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
		clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
	}
	14% {
		-webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
		clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
	}
	15% {
		-webkit-clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
		clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
	}
	16% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
		clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
	}
	18.9% {
		opacity: 1;
		-webkit-transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1,-1,1);
		        transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1,-1,1);
	}
	19%, 100% {
		opacity: 0;
		-webkit-transform: translate3d(0,0,0);
		        transform: translate3d(0,0,0);
		-webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
		clip-path: polygon(0 0, 0 0, 0 0, 0 0);
	}
}

@-webkit-keyframes glitch-anim-text {
	0% { 
		-webkit-transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0) scale3d(-1,-1,1); 
		        transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0) scale3d(-1,-1,1);
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
		clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
	}
	2% {
		-webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
		clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
	}
	4% {
		-webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
		clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
	}
	5% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
		clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
	}
	6% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
		clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
	}
	7% {
		-webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
		clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
	}
	8% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
		clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
	}
	9% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
		clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
	}
	9.9% {
		-webkit-transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0) scale3d(-1,-1,1);
		        transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0) scale3d(-1,-1,1);
	}
	10%, 100% {
		-webkit-transform: translate3d(0,0,0) scale3d(1,1,1);
		        transform: translate3d(0,0,0) scale3d(1,1,1);
		-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
	}
}

@keyframes glitch-anim-text {
	0% { 
		-webkit-transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0) scale3d(-1,-1,1); 
		        transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0) scale3d(-1,-1,1);
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
		clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
	}
	2% {
		-webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
		clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
	}
	4% {
		-webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
		clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
	}
	5% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
		clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
	}
	6% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
		clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
	}
	7% {
		-webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
		clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
	}
	8% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
		clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
	}
	9% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
		clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
	}
	9.9% {
		-webkit-transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0) scale3d(-1,-1,1);
		        transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0) scale3d(-1,-1,1);
	}
	10%, 100% {
		-webkit-transform: translate3d(0,0,0) scale3d(1,1,1);
		        transform: translate3d(0,0,0) scale3d(1,1,1);
		-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
	}
}

/* Flash */
@-webkit-keyframes glitch-anim-flash {
	0%, 5% { 
		opacity: 0.2; 
		-webkit-transform: translate3d(var(--gap-horizontal), var(--gap-vertical), 0); 
		        transform: translate3d(var(--gap-horizontal), var(--gap-vertical), 0);
	}
	5.5%, 100% {
		opacity: 0;
		-webkit-transform: translate3d(0, 0, 0);
		        transform: translate3d(0, 0, 0);
	}
}
@keyframes glitch-anim-flash {
	0%, 5% { 
		opacity: 0.2; 
		-webkit-transform: translate3d(var(--gap-horizontal), var(--gap-vertical), 0); 
		        transform: translate3d(var(--gap-horizontal), var(--gap-vertical), 0);
	}
	5.5%, 100% {
		opacity: 0;
		-webkit-transform: translate3d(0, 0, 0);
		        transform: translate3d(0, 0, 0);
	}
}






.row-2 {
	margin-top: 75px;
}

.backgroundDarkClient {
	width: 100%;
	/* animation magic */
	transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	z-index: 9999;
	top: 0;
	position: fixed;
}

header h1 {
	font-size: 30px;
	text-indent: 40px;
	font-weight: bold;
}

.shrink {
	padding: 20px 0;
	background: #20113f!important;
	padding-left: 20px;
	padding-right: 20px;
}






@media (min-width: 992px) {
	.navbar-expand-lg .navbar-collapse {
		margin: 0 20px;
	}
}

@media (max-width: 991px) {
	.interiorSignup p {
		width: 100%;
		margin-left: 0;
		margin-right: 0;
	}

	.interiorSignup h2 {
		font-size: 5rem;
	}

	.interiorSignup input {
		width: 70%;
	}

	#responsive-navbar-nav {
		display: flex!important;
	}

	.mr-auto {
		display: flex!important;
	}

	.element1 {
		/* margin-top: -40px; */
	}

	.navbar-dark .navbar-toggler {
		/* margin-top: 20px; */
	}
}
